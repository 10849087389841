import Project1 from '../assets/images/Project1.JPG';
import Project2 from '../assets/images/Project2.JPG';
import Project3 from '../assets/images/Project3.JPG';
import Project4 from '../assets/images/Project4.JPG';

export const ProjectList = [
    {
        name: "Food Ordering App",
        image: Project1,
        skills: "HTML, CSS, JavaScript, jQuery, and MongoDB",
        link: "https://github.com/J-Oswalt/Food-Order-App",
        webpage: ""
    },
    {
        name: "E-Commerce App",
        image: Project2,
        skills: "ReactJS and MongoDB",
        link: "https://github.com/J-Oswalt/e_commerce_app",
        webpage: ""
    },
    {
        name: "Landing Page",
        image: Project3,
        skills: "HTML, CSS",
        link: "https://github.com/J-Oswalt/Wilder-Hair-L.P",
        webpage: "https://j-oswalt.github.io/Wilder-Hair-L.P/"
    },
    {
        name: "Advertisement",
        image: Project4,
        skills: "HTML, CSS",
        link: "https://github.com/J-Oswalt/Advertisement",
        webpage: "https://j-oswalt.github.io/Advertisement/"
    },
]