import React from "react";
import resume from "../assets/images/Resume.JPG";
import PDF from "../assets/files/JOswalt - Resume.pdf";
import "../styles/Resume.css";

function Resume() {
  return (
    <div className="main">
      <div>
        <a href={PDF} download="JOswalt Resume" className="button">
          {" "}
          Download PDF Here{" "}
        </a>
      </div>
      <div>
        <img src={resume} alt="My Resume" />
      </div>
    </div>
  );
}

export default Resume;
