import React from "react";
import Headshot from "../assets/images/Headshot1.jpg";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import EmailIcon from "@mui/icons-material/Email";
import GitHubIcon from "@mui/icons-material/GitHub";
import "../styles/Home.css";

function Home() {
  return (
    <div className="home">
      <div className="about">
                <img src={Headshot} alt="headshot" className="headshot" />
                <h2> Hi, My Name is Joseph</h2>
            <div className="prompt">
                <p>
             I am an emerging Full Stack web developer with professional
             certification from The University of Texas at Austin. I
             successfully completed the course at the top of my class in May
             2024, showcasing a passion for creating interactive user
             experiences.{" "} {/* Prior to transitioning into web development, I have honed my skills in audio production through extensive work in live event environments, demonstrating a strong blend of technical proficiency and creative problem-solving abilities.*/}{" "}
                </p>
            </div>
        </div>
        <div className="icons">
               <a
                 href="https://www.linkedin.com/in/joseph-oswalt/"
                 target="blank"
                 className="socialMedia"
               >
                 {" "}
                 <LinkedInIcon style={{ color: "#0077b5" }} />
               </a>
               <a
                 href="mailto:josephoswalt95@gmail.com"
                 target="blank"
                 className="socialMedia"
               >
                 <EmailIcon />
               </a>
               <a
                 href="https://github.com/J-Oswalt"
                 target="blank"
                 className="socialMedia"
               >
                 <GitHubIcon />
               </a>
             </div>
      <div className="skills">
        <h1> Skills</h1>
        <div className="list">
          <h2>Front End</h2>
          <div className="fr">
            <ul>
              <li>HTML</li>
              <li>CSS</li>
              <li>jQuery</li>
              <li>ReactJS</li>
              <li>React Native</li>
              <li>NPM</li>
              <li>BootStrap</li>
              <li>MaterialUI</li>
              <li>StyledComponents</li>
            </ul>
          </div>
          <h2>Back End</h2>
          <div className="bk">
            <ul>
              <li>ExpressJS</li>
              <li>NodeJS</li>
              <li>MongoDB</li>
              <li>MySQL</li>
              <li>Postman</li>
              <li>Excel</li>
              <li className="lrn">PHP (Learning)</li>
              <li className="lrn">C# (Learning)</li>
              <li className="lrn">.NET (Learning)</li>
            </ul>
          </div>
          <h2>Languages</h2>
          <div className="lang">
            <ul>
              <li>JavaScript</li>
            </ul>
          </div>

        </div>


        {/* <ol className="list">
          <li className="item">
            <h2> Front-End</h2>
            <span>
              ReactJS, HTML, CSS, React Native, NPM, JQuery, BootStrap,
              MaterialUI, StyledComponents
            </span>
          </li>
          <li className="item">
            <h2>Back-End</h2>
            <span>NodeJS, ExpressJS, MySQL, MongoDB, Postman</span>
          </li>
          <li className="item">
            <h2>Languages</h2>
            <span>JavaScript</span>
          </li>
        </ol> */}
      </div>
    </div>
  );
}

export default Home;
