import React from "react";
import "../styles/Experience.css";
import {
  VerticalTimeline,
  VerticalTimelineElement,
} from "react-vertical-timeline-component";
import "react-vertical-timeline-component/style.min.css";
import SchoolIcon from "@mui/icons-material/School";
import WorkIcon from "@mui/icons-material/Work";

function Experience() {
  return (
    <div className="experience">
      <VerticalTimeline lineColor="#3e497a">
        <VerticalTimelineElement
          className="vertical-timeline-element--education"
          date="2015 - 2017"
          iconStyle={{ background: "#004bab", color: "#fff" }}
          icon={<SchoolIcon />}
        >
          <h3 className="vertical-timeline-element-title">
            Valencia College, Orlando Florida
          </h3>
          <h4 className="vertical-timeline-element-subtitle">
            {" "}
            AS Entertainment Design & Technology -
            <div>Live Show Production</div>
          </h4>
        </VerticalTimelineElement>
        <VerticalTimelineElement
          className="vertical-timeline-element--education"
          date="2023 - 2024"
          iconStyle={{ background: "#004bab", color: "#fff" }}
          icon={<SchoolIcon />}
        >
          <h3 className="vertical-timeline-element-title">
            The University of Texas at Austin
          </h3>

          <h4 className="vertical-timeline-element-subtitle">
            Professional Certification
          </h4>

          <p> Full Stack Software Development</p>
        </VerticalTimelineElement>
        <VerticalTimelineElement
          className="vertical-timeline-element--work"
          date="2017 - 2020"
          iconStyle={{ background: "#f05500", color: "#4d2515" }}
          icon={<WorkIcon />}
        >
          <h3 className="vertical-timeline-element-title">
            AV Technician - LMG, LLC
          </h3>
          <h4 className="vertical-timeline-element-subtitle">Nashville, TN</h4>
          <p>
            I was responsible for coordinating and executing high-profile
            events, overseeing operations of multimillion-dollar equipment,
            leading large crews of up to 60 individuals, and supervising the
            transfer of equipment on a national scale.
          </p>
        </VerticalTimelineElement>

        <VerticalTimelineElement
          className="vertical-timeline-element--work"
          date="2020 - 2021"
          iconStyle={{ background: "#f05500", color: "#4d2515" }}
          icon={<WorkIcon />}
        >
          <h3 className="vertical-timeline-element-title">
            Installation Technician - Justice AV Solutions
          </h3>
          <h4 className="vertical-timeline-element-subtitle">Louisville, KY</h4>
          <p>
            Managed custom projects from inception to completion, encompassing
            custom builds tailored to customer requirements, packing, shipping,
            installation, testing, end-user training, and post-project cleanup.
          </p>
        </VerticalTimelineElement>

        <VerticalTimelineElement
          className="vertical-timeline-element--work"
          date="2021 - 2024"
          iconStyle={{ background: "#f05500", color: "#4d2515" }}
          icon={<WorkIcon />}
        >
          <h3 className="vertical-timeline-element-title">
            Ready Mix Concrete Driver - imi Concrete
          </h3>
          <h4 className="vertical-timeline-element-subtitle">New Albany, IN</h4>
          <p>
            My duties included validating customer orders, aligning customer
            requests, preparing concrete mixes, ensuring prompt material
            deliveries, upkeeping the facility, and performing daily maintenance
            on company equipment.
          </p>
        </VerticalTimelineElement>

        <VerticalTimelineElement
          className="vertical-timeline-element--work"
          date="2024 - Present"
          iconStyle={{ background: "#f05500", color: "#4d2515" }}
          icon={<WorkIcon />}
        >
          <h3 className="vertical-timeline-element-title">
            Truck Driver - Kentuckiana Trucking
          </h3>
          <h4 className="vertical-timeline-element-subtitle">Clarksville, IN</h4>
          <p>
            My duties included validating customer orders, aligning customer
            requests, ensuring prompt material
            deliveries, upkeeping the facility, and performing daily maintenance
            on company equipment.
          </p>
        </VerticalTimelineElement>
      </VerticalTimeline>
    </div>
  );
}

export default Experience;
