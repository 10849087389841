import React from "react";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import "../styles/Footer.css";

function Footer() {
  return (
    <div className="footer">
      <div className="socialMedia">
        <a href="https://www.linkedin.com/in/joseph-oswalt/" target="blank">
          <LinkedInIcon style={{ color: "#0077b5" }} />
        </a>
      </div>
      <p> &copy; 2024 oswaltdev.com</p>
    </div>
  );
}

export default Footer;
